<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  beforeRouteEnter(to, from, next) {
    // Используем функцию next с колбэком, который будет вызван после загрузки компонента
    next((vm) => {
      // Используем метод scrollTo для установки координат прокрутки страницы
      vm.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    });
  },
}
</script>

<template>
<div class="container-ter">
    <Header/>
    <div class="container2">
        <div class="privacy-page-container row">
        <div class="privacy-header-container col-12 col-lg-12">
            <div class="privacy-page-name-container privacy-policy">TERMS AND CONDITIONS OF USE</div>
        </div>
        <div class="col-lg-3">
            <div class="privacy-sidebar-container">
                <div class="privacy-sidebar-header">Table of contents</div>
                <ul class="none-styled">
                    <li><a href="#general">1. GENERAL PROVISIONS</a></li>
                    <li><a href="#point2">2. USE OF SERVICES AND RULES OF CONDUCT</a></li>
                    <li><a href="#point3">3. MONITORING OF SERVICES BY coinwealth</a></li>
                    <li><a href="#point4">4. DISCLAIMER AND USER WARRANTIES</a></li>
                    <li><a href="#point5">5. coinwealth DOES NOT OFFER INVESTMENT ADVICE OR BROKERING SERVICES</a></li>
                    <li><a href="#point6">6. coinwealth DOES NOT OFFER TAX ADVICE OR LEGAL ADVICE</a></li>
                    <li><a href="#point7">7. USER WARRANTIES</a></li>
                    <li><a href="#point8">8. INTELLECTUAL PROPERTY</a></li>
                    <li><a href="#point9">9. LIABILITY</a></li>
                    <li><a href="#point10">10. AWARENESS OF RISKS</a></li>
                    <li><a href="#point11">11. PAYMENT</a></li>
                    <li><a href="#point12">12. REFERRAL PROGRAM</a></li>
                    <li><a href="#point13">13. THIRD-PARTY WEBSITE AND CONTENT</a></li>
                    <li><a href="#point14">14. ADVERTISER</a></li>
                    <li><a href="#point15">15. AMENDMENTS</a></li>
                    <li><a href="#point16">16. FINAL PROVISIONS</a></li>
                </ul>
            </div>
        </div>
        <div class="privacy-content-container col-12 col-lg-8">
            <div class="privacy-info-block">
                <div id="general" class="header-text-block">1. GENERAL PROVISIONS</div>
                <div>
                    <p>BY ACCESSING AND USING coinwealth.group, YOU CONFIRM THAT YOU HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS OF USE AND PRIVACY POLICY, AND YOU UNCONDITIONALLY AGREE TO BE BOUND BY THEM. YOU ALSO ACKNOWLEDGE THAT YOU UNDERSTAND AND ACCEPT THE DISCLAIMER PROVIDED BY coinwealth.group, AND YOU DECLARE THAT THE INFORMATION YOU PROVIDE WHILE USING THE WEBSITE IS TRUTHFUL. YOUR USE OF THE WEBSITE SIGNIFIES YOUR AGREEMENT TO COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS, AND YOU ARE SOLELY RESPONSIBLE FOR ADHERING TO ANY LOCAL LAWS. PLEASE NOTE THAT THIS WEBSITE IS PROTECTED BY COPYRIGHT LAW. WE RESERVE THE RIGHT TO REFUSE SERVICE TO ANY INDIVIDUAL WITHOUT PROVIDING A REASON, AT ANY TIME.</p>
                    <p>1.1. This agreement regulates the legal relationship between the user of the coinwealth website (hereinafter referred to as "the User") and the administration of this website (hereinafter referred to as "the Administration," "We", "Us", or "Our"), arising in the process of using the services of this website (hereinafter referred to as "the Services" or "Website"). The agreement prescribes the basic rights and obligations of the parties, and responsibilities that arise in the case of a default on obligations (hereinafter referred to as "the Agreement" or "the Terms of Use").</p>
                    <p>1.2. The user is an individual who has reached the legal age of their respective citizenship or residence, and has completed the registration process on this site by opening an account. By doing so, the user confirms that they have read and agreed to the privacy policy. It is a requirement for the user to be of legal age, regardless of their place of registration or use.</p>
                    <p>1.3. The account owner is the individual who possesses the password and email address associated with the account. It is your responsibility to maintain the confidentiality of your password and account information. You are fully accountable for any activities conducted under your password or account. By using the services of coinwealth, you agree to the following:</p>
                    <ol type="1">
                        <li>Promptly notify coinwealth of any unauthorized use of your password or account, or any other security breaches.</li>
                        <li>Ensure that you log out from your account at the end of each session. coinwealth cannot and will not be held liable for any loss or damage resulting from your failure to comply with this requirement.</li>
                    </ol>
                    <p>1.4. By utilizing this website, users are granted the ability to participate in games and simulate the mining of cryptocurrency coins and tokens. Additionally, users may engage in in-game purchases to enhance their gameplay experience.</p>
                    <p>1.5. Users of coinwealth who are found to be involved in activities prohibited by the acceptable use policy may face consequences such as service suspension or account termination. In severe cases, we may be legally obligated to report such individuals to the appropriate authorities.</p>
                    <p>1.6. coinwealth is strictly a gaming project and should not be construed or treated as an investment platform, financial instrument, or blockchain mining software.</p>
                    <p>1.7. Users are solely responsible for ensuring that their use of the coinwealth website complies with the laws and regulations of their jurisdiction. It is strictly prohibited to employ the coinwealth website for any illegal or fraudulent purposes, including but not limited to money laundering, terrorist financing, and other financial crimes.</p>
                    <p>1.8. Users must adhere to all applicable laws and regulations, including those pertaining to cryptocurrency and blockchain technology.</p>
                    <p>1.9. coinwealth reserves the right to request additional information from users, such as identification documents, to verify their identity and ensure compliance with applicable laws and regulations.</p>
                    <p>1.10. Unauthorized use of automated scripts, bots, or other software to interact with the coinwealth website is strictly prohibited unless expressly authorized by coinwealth.</p>
                    <p>1.11. coinwealth retains the right to modify, suspend, or terminate the services provided on the website, as well as the terms and conditions of this agreement, at any time and without prior notice to users.</p>
                    <p>1.12. Users acknowledge and agree that coinwealth shall not be held liable for any damages or losses resulting from their use of the website, including but not limited to loss of profits, data, or other intangible losses.</p>
                    <p>1.13. coinwealth may collect and process users' personal data in accordance with the Privacy Policy. Users can exercise their rights pertaining to personal data by contacting the coinwealth support team.</p>
                    <p>1.14. Users acknowledge that the virtual currency and items obtained through the coinwealth website hold no real-world value and cannot be exchanged for legal tender or other tangible assets.</p>
                    <p>1.15. coinwealth reserves the right to modify the virtual currency and items obtained through the website, including their value, at any time and without prior notice to users.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point2" class="header-text-block">2. USE OF SERVICES AND RULES OF CONDUCT</div>
                <div>
                    <p>2.1. Use of Services.</p>
                    <ol type="1">
                        <li>In order to use the Services provided, you must be authorized and comply with the terms outlined in these Terms of Use or any other applicable terms for the specific Services.</li>
                        <li>The Services are intended for personal use only, and under no circumstances should they be used for commercial purposes.</li>
                        <li>You are prohibited from engaging in the following activities, directly or indirectly:</li>
                        <ol type="a">
                            <li>Selling, renting, or marketing any form of content (referred to as "Content") available on the Website.</li>
                            <li>Selling, renting, or purchasing existing coinwealth accounts.</li>
                            <li>Modifying, decompiling, disassembling, reverse engineering, translating, adapting, reproducing, distributing, disseminating, reposting, or transferring any element of the Content.</li>
                            <li>Automating or using the Content on a large scale.</li>
                            <li>Transferring any Content between computers or mobile devices, except as required for normal use of the Services.</li>
                        </ol>
                        <li>If the Services include the option to download the Content upon request, you may do so onto your computer(s) or mobile device(s) and print the applicable pages, provided that you:</li>
                        <ol type="a">
                            <li>Retain all copyright, trademark, authorship, and origin information.</li>
                            <li>Do not copy or distribute the Content, or create derivative works based on the Content, except for copying involved in the normal use of the Services or creating a single backup copy.</li>
                            <li>Do not use the Content in a manner that implies an association with coinwealth's products, services, or brand names.</li>
                        </ol>
                        <li>If you violate these Terms of Use by printing, copying, or downloading any part of our Website, your right to use our Services will immediately cease, and you must, at our discretion, return or destroy any copies of the materials you have made.</li>
                        <li>You are solely responsible for any damage caused to coinwealth, its partners, other Users of the game and Services, or any other individuals or legal entities resulting from your use of the Services in violation of the law, regulations, or your obligations as outlined in these Terms of Use.</li>
                        <li>If you provide any outdated, untrue, incomplete, or false information, we reserve the right to delete, suspend, or ban your account in the game and/or ban your access to our social media, chats, and contact email.</li>
                    </ol>
                    <p>The User is solely responsible for all material you input, upload, disseminate, transmit, create, or publish through or on the Services, and for obtaining legal permission to use any works included in such material.</p>
                    <p>2.2. Rules of Conduct.</p>
                    <p>By using the Services, you agree to comply with the following rules of conduct outlined in this section. The purpose of these rules is to ensure lawful and proper use of the Services.</p>
                    <ol type="1">
                        <li>You may only use the Services for lawful purposes and in accordance with their intended use.</li>
                        <li>It is strictly prohibited to use the Services to transmit, distribute, or store any material that violates applicable laws. This includes, but is not limited to, the following actions:</li>
                        <ol type="a">
                            <li>Using the Services in a manner that breaches local, national, or international laws or regulations.</li>
                            <li>Engaging in any activity that infringes upon the intellectual property rights or other rights of coinwealth or others.</li>
                            <li>Creating, using, sharing, or publishing material (such as text, images, sounds, videos) that breaches confidentiality, violates individuals' right to privacy, or incites unlawful acts (such as piracy, cracking, or circulation of counterfeit software).</li>
                            <li>Accessing or attempting to access parts of the Services without authorization from coinwealth.</li>
                            <li>Modifying, distorting, blocking, disrupting, slowing down, or hindering the normal functioning of the Services or their accessibility to other Users, or the functioning of partner networks of the Services.</li>
                            <li>Transmitting or propagating any viruses, trojan horses, worms, bombs, corrupted files, or similar destructive devices or data in relation to the Services, or participating in attacks on coinwealth servers, the Services, or the servers of its service providers and partners.</li>
                            <li>Creating, using, or circulating computer programs (such as cheats, mods, hacks, bots, trainers) or using the Services via mirror sites without explicit authorization from coinwealth.</li>
                            <li>Using incorrect information, assuming another User's Account or identity, or providing false credentials related to any individual or legal entity in connection with the Services.</li>
                            <li>Collecting or intercepting data exchanged by other Users without express permission from coinwealth, including names/usernames and passwords of other Users.</li>
                            <li>Attempting to obtain passwords or private information from other Users or selling, renting, sharing, lending, or otherwise transferring your Account or its access to any outside party.</li>
                            <li>Misusing the help service, claim buttons, or sending false reports to coinwealth personnel.</li>
                            <li>Accessing, using, downloading, reproducing, or supplying to others any directory of Users or any other information related to Users or the use of the Services without permission.</li>
                            <li>Refusing to comply with instructions from coinwealth representatives or falsely claiming to be an employee or representative of coinwealth or its partners or agents.</li>
                            <li>Harassing other Users in any way, which may result in a ban and loss of funds in your account.</li>
                            <li>Engaging in other unethical, exploitative, or malicious activities, including activities related to illegal gambling, terrorism, narcotics trafficking, arms trafficking, or the proliferation of weapons of mass destruction or missiles. This includes any affiliation with individuals or groups involved in such activities.</li>
                        </ol>
                        <li>Other activities considered unethical, exploitative, and malicious include:</li>
                        <ol type="a">
                            <li>Obtaining (or attempting to obtain) services from us with the intent to avoid payment;</li>
                            <li>Using our facilities to obtain (or attempt to obtain) services from another provider with the intent to avoid payment;</li>
                            <li>The unauthorized access, alteration, or destruction (or any attempt thereof) of any information about our Users or end-users, by any means or device;</li>
                            <li>Using our facilities to interfere with the use of our facilities and network by other customers or authorized individuals;</li>
                            <li>Publishing or transmitting any content of links that incite violence, depict a violent act, depict child pornography, or threaten anyone’s health and safety;</li>
                            <li>Any act or omission violating consumer protection laws and regulations;</li>
                            <li>Any violation of a person’s privacy.</li>
                        </ol>
                        <li>coinwealth products and/or Services may not be used by any person or entity which is involved with or suspected of involvement in activities or causes relating to illegal gambling; terrorism; narcotics trafficking; arms trafficking or the proliferation, development, design, manufacture, production, stockpiling, or use of nuclear, chemical or biological weapons, weapons of mass destruction, or missiles; in each case including any affiliation with others whatsoever who support the above such activities or causes.</li>
                        <li>The Services must not be used for advertising, transmitting, or providing access to any software, program, product, or service designed to violate this acceptable use policy or the policies of other service providers. This includes facilitating activities such as spamming, network sniffing, pinging, packet spoofing, flooding, mail-bombing, and denial-of-service attacks.</li>
                        <li>The Services must not be used to access accounts or electronic resources without ownership or authorization (e.g., hacking, cracking, phreaking).</li>
                        <li>The Services must not be used to introduce viruses or malicious code intentionally or recklessly into coinwealth's products and systems.</li>
                        <li>The Services must not be used for engaging in activities intended to harass individuals or groups. Harassment includes, but is not limited to, denial-of-service attacks, hate speech, advocacy of racial or ethnic intolerance, and any activity intended to threaten, abuse, infringe upon the rights of, or discriminate against any individual or group.</li>
                    </ol>
                    <p>2.3. When utilizing the coinwealth website, the User agrees to the collection, processing, and storage of their personal data in adherence to the Privacy Policy. The User acknowledges that their personal data may be utilized for the provision and enhancement of the services provided on the website, as well as for marketing and communication endeavors. The User retains the right to revoke their consent at any time by contacting coinwealth's support team.</p>
                    <p>2.4. Content Standards</p>
                    <p>In relation to the Services, you are prohibited from:</p>
                    <ol type="1">
                        <li>creating, using, sharing, or publishing through any means (such as forums, public profiles, or others) any material (including text, words, images, sounds, videos, etc.) or Content that, in the sole discretion of coinwealth, is deemed unlawful, aggressive, threatening, malicious, defamatory, false, pornographic, pedophilic, obscene, vulgar, racist, xenophobic, promoting hatred, sexually explicit, insulting, violent, contrary to morality, or in any way unacceptable;</li>
                        <li>harassing other Users, sending unsolicited advertisements, promotional materials, or messages for commercial purposes, using the Services for surveys, competitions, pyramid schemes, or similar operations, or engaging in mass emails or spamming activities.</li>
                    </ol>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point3" class="header-text-block">3. MONITORING OF SERVICES BY coinwealth</div>
                <div>
                    <p>3.1. We are not responsible for and do not endorse the opinions, advice, and/or recommendations posted or transmitted by Users on the Services, including in public message forums. The User is solely responsible for such communications.</p>
                    <p>3.2. We may, but are not obligated to, monitor the Content, messages, and other information available on the Services by Users, in accordance with applicable legal requirements. We may choose to oversee, monitor, or moderate our Services, especially on website home pages and forums.</p>
                    <p>3.3. The Administration reserves the right, at its sole discretion, to reject any username, screen name, and/or password chosen by you.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point4" class="header-text-block">4. DISCLAIMER AND USER WARRANTIES</div>
                <div>
                    <p>4.1. coinwealth.group does not offer investment, financial, or legal advice. The information provided on this website is for informational purposes only and should not be considered as a substitute for professional advice or independent verification of facts. To access and use the content on this website, you must agree to coinwealth.group's Terms and Conditions, disclaimers, and the warranties mentioned below.</p>
                    <p>4.2. coinwealth.group, its owners, officers, employees, directors, or agents are not affiliated with, endorse, or sponsor any token sales. In some cases, coinwealth.group may receive advertising fees for certain token sales, which will be clearly indicated.</p>
                    <p>4.3. coinwealth.group does not provide recommendations or advice regarding token sales.</p>
                    <p>4.4. It is your responsibility to conduct thorough research.</p>
                    <p>4.5. Participating in token sales, buying, selling, or holding tokens involves risks and should be based on advice from qualified financial professionals.</p>
                    <p>4.6. The content provided by coinwealth.group is intended for informational purposes only. It is crucial to conduct your own research and analysis based on your personal circumstances. You should seek independent financial advice or independently verify any information found on coinwealth.group before making any investment decisions.</p>
                    <p>4.7. It is important to be aware of the potential risks associated with token sales and transactions involving digital tokens. Since these transactions may take place online and may not be regulated, there is a higher risk of fraud, limited liquidity, and volatile or opaque pricing. Before making a purchase, you should fully understand the features of the products or projects you intend to fund and carefully evaluate the risks versus the potential returns.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point5" class="header-text-block">5. coinwealth DOES NOT OFFER INVESTMENT ADVICE OR BROKERING SERVICES</div>
                <div>
                    <p>5.1. coinwealth.group explicitly states that it is not an investment advisor or broker. The information provided on token sales does not take into account your personal circumstances. coinwealth.group does not engage in activities that require registration as an investment advisor or broker. The content on the website should not be interpreted as a recommendation to engage in securities transactions or investments, nor does it constitute an endorsement, recommendation, or rating of any project or investment. We do not offer personalized recommendations or opinions on whether a project or investment strategy is suitable for an individual's financial needs.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point6" class="header-text-block">6. coinwealth DOES NOT OFFER TAX ADVICE OR LEGAL ADVICE</div>
                <div>
                    <p>6.1. coinwealth.group explicitly states that it does not offer accounting services or legal advice. We are not qualified accountants or lawyers. The content on the website should not be interpreted as legal or tax advice. We strongly advise you to seek independent advice from a licensed professional for any legal or tax matters.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point7" class="header-text-block">7. USER WARRANTIES</div>
                <div>
                    <p>7.1. You acknowledge and warrant that you will make every effort, exercising due diligence and to the best of your knowledge and belief, to prevent any involvement in money laundering or any other unlawful activities in connection with your use of coinwealth.group. Furthermore, you confirm that no proceeds from any money laundering activities will be used to acquire any game internal token such as LMT or any other token listed on coinwealth.group. You also guarantee that you will always comply with all applicable anti-money laundering regulations. Additionally, you undertake not to provide anything of value, whether directly or indirectly, to any government official, employee of a government-controlled company, political party, or any private individual or entity acting on behalf of a government, in order to gain any improper advantage. You further warrant that no funds received as compensation or in any other manner will be used to facilitate bribes or kickbacks in violation of applicable laws.</p>
                    <p>7.2. The User agrees to indemnify, defend, and hold harmless coinwealth, its affiliates, and their directors, officers, and employees from any and all taxes, losses, damages, liabilities, costs, and expenses, including attorneys' fees and other legal expenses, arising directly or indirectly from any violation or alleged violation of a third party's rights resulting, in whole or in part, from coinwealth's use of the intellectual property rights or other artworks transferred to coinwealth under this Agreement.</p>
                    <p>7.3. The User represents and warrants to coinwealth that they possess all necessary rights, full legal power, and authority to grant a non-exclusive perpetual license for the artworks created within the process of using the Website.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point8" class="header-text-block">8. INTELLECTUAL PROPERTY</div>
                <div>
                    <p>8.1. The materials contained in this website, including intellectual property rights, are owned by or licensed to coinwealth and are protected by relevant copyright and trademark laws.</p>
                    <p>8.2. By participating in the activity on the Website that results in the creation of images (referred to as "artworks"), the User grants coinwealth a non-exclusive perpetual license to reproduce and display images of their artwork on our website, in our print media, newsletters, exhibitions, promotional materials, and any other marketing materials related to future art competitions, for the purpose of promoting these events to the general public.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point9" class="header-text-block">9. LIABILITY</div>
                <div>
                    <p>9.1. Accordingly, coinwealth.group will not be liable, whether in contract, tort (including negligence) or otherwise, in respect of any damage, expense or other loss you may suffer arising out of such information or any reliance you may place upon such information. You should verify any information that you find on our website that you wish to rely on, whether for investment decisions or otherwise.</p>
                    <p>9.2. The use of any data or information provided by coinwealth or through the coinwealth.group website, including information about token sales and our internal game data (referred to as "Information"), does not guarantee profits or prevent potential losses. You should exercise your own judgment or seek professional advice when dealing with such matters.</p>
                    <p>9.3. The Information is intended solely for informational and educational purposes.</p>
                    <p>9.4. You acknowledge and agree that the Information is not intended to provide professional, legal, or financial advice. coinwealth.group does not offer advice regarding the nature, potential value, suitability, or any specific coin or token sale. You should not interpret any Information displayed, published, or provided by coinwealth as legal, tax, investment, financial, or any other form of advice.</p>
                    <p>9.5. Nothing on the coinwealth.group website constitutes a solicitation to buy, sell, or hold coins and/or tokens.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point10" class="header-text-block">10. AWARENESS OF RISKS</div>
                <div>
                    <p>10.1. It is essential to thoroughly evaluate whether the Information is suitable for your individual financial situation and risk tolerance.</p>
                    <p>10.2. All information, data, white papers, and other materials related to a specific token sale are solely prepared by the organizer, and they bear sole responsibility for the accuracy of their statements. There is no guarantee regarding the truthfulness, correctness, or precision of the information provided on these matters.</p>
                    <p>10.3. YOU ALONE ASSUME SOLE RESPONSIBILITY FOR ANY DECISIONS YOU MAKE BASED ON THE INFORMATION AND/OR THE USE OF coinwealth.group. ALL INFORMATION IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND. coinwealth.group MAKES NO REPRESENTATIONS AND DISCLAIM ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES OF ANY KIND TO USER AND/OR ANY THIRD PARTY, INCLUDING WARRANTIES AS TO ACCURACY, TIMELINESS, COMPLETENESS, MERCHANTABILITY, OR FITNESS FOR ANY PARTICULAR PURPOSE. UNDER NO CIRCUMSTANCES SHALL coinwealth.group BE LIABLE FOR ANY LOSS OR DAMAGE YOU OR ANYONE ELSE INCURS AS A RESULT OF ANY ACTIVITY THAT YOU OR ANYONE ELSE ENGAGES IN BASED ON ANY INFORMATION YOU RECEIVE THROUGH coinwealth.group OR AS A RESULT OF THE USE OF coinwealth.group.</p>
                    <p>10.4. By purchasing internal tokens (such as LMT or any other) and investing in cryptocurrency tokens, you acknowledge and understand that these activities carry significant risks and are highly speculative in nature. These tokens are unregulated, operate in an early stage of development, utilize experimental software and business models, lack governmental protection for your investment, exhibit extreme price volatility, may have inadequate documentation, and pose a high risk of fraud.</p>
                    <p>10.5. It is important to note that the purchase of internal virtual tokens (such as LMT or others) or investment in cryptocurrency token projects should only be undertaken if you possess substantial technical knowledge and have a comprehensive understanding of the specific offering. Thorough due diligence should be conducted on the projects, networks, tokens, and teams associated with any token sale. It is crucial to recognize that your investment, as well as others', may not result in the acquisition of a usable or valuable token, and there is a possibility of losing the entire value of your investment.</p>
                    <p>10.6. Please be aware that the token sales discussed on the coinwealth.group website have not undergone review by any regulatory authority.</p>
                    <p>10.7. The accuracy or sufficiency of the offering documents for token sales has not been confirmed or determined by regulatory authorities. Any claim to the contrary is considered a criminal offense.</p>
                    <p>10.8. Tokens may constitute securities pursuant to applicable security laws.</p>
                    <p>10.9. Tokens may not be suitable for or offered to investors residing in the United States. The Securities and Exchange Commission (SEC) has cautioned investors residing in the United States that token sales may qualify as securities, and by investing in tokens, these investors may be participating in unregistered securities offerings. In the event of fraud or theft, US investors who invest in tokens may have limited or no recourse to recover any losses incurred.</p>
                    <p>coinwealth.group not providing services and not accept gamers from List of Comprehensively Sanctioned Countries</p>
                    <ul>
                        <li>Cuba</li>
                        <li>Iran</li>
                        <li>Syria</li>
                        <li>Russian Federation</li>
                        <li>Belarus</li>
                        <li>The following regions of Ukraine: Crimea, Donetsk, Luhansk</li>
                        <li>Northern Cyprus</li>
                        <li>North Korea</li>
                        <li>Central African Republic</li>
                        <li>Dem Rep of Congo</li>
                        <li>Lebanon</li>
                        <li>Yemen</li>
                        <li>Zimbabve</li>
                        <li>Venezuela</li>
                        <li>Vietnam</li>
                        <li>Cote d'ivoire</li>
                    </ul>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point11" class="header-text-block">11. PAYMENT</div>
                <div>
                    <p>11.1. All transactions conducted via this website are calculated in crypto.</p>
                    <p>11.2. The internal token LMT or any other token is a virtual internal currency that is used solely to represent your current balance on the Website and does not possess any intrinsic or real-world value.</p>
                    <p>11.3. Any deposits (deposit BTC, etc) from the Website will be processed through the blockchain using a cryptocurrency wallet.</p>
                    <p>11.4. Please note that coinwealth does not own or control the blockchain, the browser, or any other third-party services that users may utilize during operations on the website.</p>
                    <p>11.5. We do not have access to or control over these payments or transactions, nor do we possess the ability to reverse any transactions. Therefore, we hold no liability to you or any third party for any claims or damages that may arise from the transactions you engage in on the Website or any other transactions you conduct.</p>
                    <p>11.6. Regarding Taxes, you are solely responsible for paying any and all sales, use, value-added, and other taxes, duties, and assessments (excluding taxes on our net income) that may be claimed or imposed by any governmental authority. These Taxes are associated with your use of the Website or Services, including any Taxes that may be payable as a result of your ownership, transfer, or mining activities within the Website.</p>
                    <p>You agree to: (i) Pay or reimburse us for all national, federal, state, local, or other taxes and assessments from any jurisdiction, including value-added taxes and taxes required by international tax treaties, customs duties, and other import or export taxes, based on charges set, services performed, or payments made under these Terms of Use, as may be currently imposed or imposed in the future by any national, state, local, or other taxing jurisdiction. (ii) You are not entitled to deduct the amount of any such taxes, duties, or assessments from payments made to us in accordance with these Terms and Conditions of Use.</p>
                    <p>11.7. All transaction costs arising from the transactions between Us and Users shall be paid or compensated by the User.</p>
                    <p>11.8. The User agrees to take all necessary measures to ensure the security of their account and prevent unauthorized access, thereby safeguarding their identity and balance from theft.</p>
                    <p>11.9. Once a User has made a deposit, the funds are non-refundable.</p>
                    <p>11.10. The User has the flexibility to add or withdraw funds from their account at any time.</p>
                    <p>11.11. The Administration reserves the right to temporarily freeze a portion of a User's balance for manual verification purposes.</p>
                    <p>11.12. The User is only permitted to withdraw funds from their available balance.</p>
                    <p>11.13. The processing time for both deposits and withdrawals may range from 1 to 7 business days, depending on the transaction amount.</p>
                    <p>11.14. The User must ensure the correct network is used for processing deposits and withdrawals. Sending or withdrawing cryptocurrency via the wrong currency network, wallet, or to the wrong currency wallet may result in irreversible transactions and potential loss of funds.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point12" class="header-text-block">12. REFERRAL PROGRAM</div>
                <div>
                    <p>12.1. This paragraph is aimed to define the process of providing the User with the rights of enjoyment and gaining commissions from participation in the referral program.</p>
                    <p>12.2. For the purposes of this section the following are explained:</p>
                    <ol type="1">
                        <li>Referrer – is the User participating in the referral program and receiving compensation for engagement of every new User;</li>
                        <li>Referral – a new User, engaged by a Referrer.</li>
                    </ol>
                    <p>12.3. All users have the right to participate in the referral program.</p>
                    <p>12.4. Each user will receive a unique ID and a special link to share with others. When new users click on the link and register on the website, they will be automatically marked as referrals.</p>
                    <p>12.5. Newly registered users are checked for referral records. If they were invited by an existing user, they become indefinite referrers of that user.</p>
                    <p>12.6. Referring users (referrers) earn income based on the number of new users they have invited and the current terms of the referral program.</p>
                    <p>12.7. The referral system is designed only for advertising purposes. Any violations and abuses of the referral system are prohibited, such as:</p>
                    <ol type="1">
                        <li>Registration of personal accounts using your own referrer link and make in-game purchases on referral accounts to receive commissions.</li>
                        <li>Sharing your referral link with family members and roommates to receive commissions on their virtual mining and in-game purchases.</li>
                        <li>These violations will lead to the permanent ban of the referrer account.</li>
                        <li>In some cases, coinwealth administration can remove referrals from the referrer account if a violation of the referral system appears.</li>
                    </ol>
                    <p>12.8. The Administration retains the right to modify the conditions of the referral program, including the Terms and Conditions of use and commission structure, without prior notice to users. Users can find the current conditions of the referral program on the webpage: coinwealth.group/referral-program. coinwealth ensures that all dividends and commissions related to the referral program will be added and calculated in accordance with the applicable conditions.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point13" class="header-text-block">13. THIRD-PARTY WEBSITE AND CONTENT</div>
                <div>
                    <p>13.1. The Services provided may include links to third-party websites ("Third-Party Websites") and display content from third parties ("Third-Party Content"). We do not investigate, monitor, or verify the accuracy, appropriateness, or completeness of these Third-Party Websites or Third-Party Content. We are not responsible for any Third-Party Websites accessed through our Website or any Third-Party Content posted on, available through, or installed from our Website. This includes the content, accuracy, opinions, reliability, privacy practices, or other policies of the Third-Party Websites or Third-Party Content. The inclusion of links or the availability of Third-Party Content does not imply our approval or endorsement. If you choose to access Third-Party Websites or use or install Third-Party Content, you do so at your own risk, and these Terms and Conditions of Use no longer apply. You should review the applicable terms, policies, and practices, including privacy and data gathering practices, of any third-party website you visit or any applications you use or install. Any purchases you make through Third-Party Websites are solely between you and the third party, and we are not responsible for such purchases. We do not endorse the products and services offered on Third-Party Websites, and you agree to hold us harmless from any harm or losses resulting from your purchase of such products and services. Furthermore, you agree to hold us harmless from any losses or harm caused by Third-Party Content or any interaction with Third-Party Websites.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point14" class="header-text-block">14. ADVERTISER</div>
                <div>
                    <p>14.1. We offer advertising space on the Website, where advertisers can display their advertisements and other information, such as sidebar ads or banners. As an advertiser, you are solely responsible for the content of your advertisements, as well as any services or products promoted through those ads, on the Site and/or the App. By placing advertisements on the Website, you warrant and represent that you have the necessary rights and authority, including intellectual property rights, publicity rights, and contractual rights, to do so. We provide the platform for displaying these advertisements but do not have any other affiliation or relationship with advertisers.</p>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point15" class="header-text-block">15. AMENDMENTS</div>
                <div>
                    <p>15.1. We reserve the right to modify these Terms and Conditions of Use and any other applicable policies at any time by publishing the revised version on our website. The revised version will be effective from the earlier of:</p>
                    <ol type="1">
                        <li>the date the Users uses of the Services after we publish the revised version on our website; or</li>
                        <li>15 days after we publish the revised version on our website.</li>
                    </ol>
                </div>
            </div>
            <div class="privacy-info-block">
                <div id="point16" class="header-text-block">16. FINAL PROVISIONS</div>
                <div>
                    <p>16.1. These Terms and Conditions of Use remain in full force and effect while you use the Website. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE WEBSITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES) TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE WEBSITE OR DELETE YOUR ACCOUNT WITHOUT WARNING IN OUR SOLE DISCRETION.</p>
                    <p>16.2. In the event of account termination or suspension, you are strictly prohibited from creating a new account using your name, a fictitious name, or the name of any third party, even if you are acting on behalf of that third party. In addition to terminating or suspending your account, we reserve the right to pursue appropriate legal action, including civil, criminal, and injunctive remedies.</p>
                    <p>16.3. The Administration holds no responsibility for any damages or losses incurred by the User as a result of hacking or funds theft from their account.</p>
                    <p>16.4. The Administration is not responsible to users, publishers or any third parties for claims regarding the violation of intellectual property rights and the compensation of damages.</p>
                    <p>16.5. If the User encounters technical or other issues while using this website, they should promptly contact the Administration and provide a clear explanation of the problem. The Administration is committed to responding and making every effort to resolve the issue within 30 days of receiving the User's notification.</p>
                    <p>16.6. Any disputes between the parties shall be resolved through negotiations.</p>
                    <p>Last updated: July 01 2023</p>
                </div>
            </div>
        </div>
    </div>
    </div>
    <Footer/>
</div> 
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.group/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.group/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&display=swap');


* {
    font-family: 'Poppins', sans-serif;
    color: rgb(255, 255, 255);
    
}



.container-ter{
    min-width: 99vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: -12px;
    overflow-x: hidden;
}

.container2{
    max-width: 1020px;
    margin-left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
}

.privacy-page-name-container {
    font-size: 36px;
    letter-spacing: 3.6px;
    margin-left: 40px;
    margin-right: 32px;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .privacy-sidebar-container {
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  border-radius: 2px;
  background: rgb(34, 36, 50);
}

.privacy-sidebar-header {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
}

.privacy-page-container .privacy-sidebar-container ul li a {
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
  font-size: 12px;
}

.privacy-info-block {
    padding-top: 12px;
  margin-bottom: 8px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 20px;
  word-break: normal;
}

dl, ol, ul {
  margin-top: 0px;
}



.header-text-block {
    word-break: normal;
  width: 100vw;
  margin-bottom: 4px;
  color: rgb(248, 248, 248);
  font-size: 16px;
  font-family: 'IBM Plex Mono';
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}


@media screen and (min-width: 993px) {  
.privacy-policy {
    margin-left: 80px;
    margin-right: 40px;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-weight: 600;
  }
}


@media screen and (max-width: 992px) {
    Header{
        padding: 0 0 24px 0;
    }
    .col-lg-3 {
        display: none;
    }
    .privacy-content-container{
        padding: 0 12.5%;
    }
    .privacy-policy{
        margin-right: 0;
        margin-left: 0;
        font-size: 20px;
        padding: 0 12%;
    }
}

@media screen and (max-width: 570px){
    .privacy-content-container{
        padding: 0 9px;
    }
    .privacy-policy{
        padding: 0;
    }
}


</style>